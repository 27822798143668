function checkUsername(str) {
  const len = str.length;
  let char_number = 0;
  if (!str) {
    return false;
  }
  for (const character of str) {
    if (character >= "0" && character <= "9") {
      char_number += 1;
    }
  }
  if (char_number == len) {
    // all char is number
    return "กรุณาเพิ่มตัวอักษรภาษาอังกฤษ";
  } else {
    return true;
  }
}
export const rules = {
  empty: [
    (v) => !!v || "Please fill in required fields.",
    // v => /^[ก-๏A-Za-z0-9]+$/.test(v) || "กรุณากรอกข้อมูลให้ถูกต้อง",
    (v) => (v ? v[0] != " " : false) || "Do not begin with a blank value.",
    // v => (!!v || v ? v.length <= 50 : false) || "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร"
  ],

  //add user
  staff_id_rules: [
    (v) => !!v || "Please fill out the correct information.",
    (v) =>
      /^[ก-๏A-Za-z0-9-./_ ]+$/.test(v) ||
      "Please fill out the correct information.",
    (v) =>
      (v ? v[0] != " " : false) || "Please fill out the correct information.",
  ],

  password_rules: [
    (v) => !!v || "Please enter your password.",
    (v) => (v ? v[0] != " " : false) || "Do not begin with a blank value.",
    (v) =>
      /^((?=.*[a-zA-Za-z0-9]).{6,})+$/.test(v) ||
      "Please enter your password in number and english, Min 6 characters.",
  ],

  tasknumber_rules: [
    (v) =>
      /^[0-9.!#$%&'*+/=?^_`{|}~-]*$/.test(v) ||
      "The Invalid Task Number Formats",
  ],

  name_staffid_rules: [
    (v) =>
      /^[ก-๏A-Za-z0-9.!#$%&'*+" "/=?^_`{|}~-]*$/.test(v) ||
      "The Invalid Task Number Formats",
  ],

  username_rules: [
    (v) => !!v || "กรุณากรอกชื่อผู้ใช้",
    // v => ((!!v) ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) => checkUsername(v),
    (v) =>
      /^([A-Za-z0-9])+$/.test(v) ||
      "กรุณาใส่ชื่อผู้ใช้งานเป็นภาษาอังกฤษหรือตัวเลข",
    // v => /^((?=.*[a-zA-Z])(?=.*[0-9]).{6,})+$/.test(v) ||
    //     "ชื่อผู้ใช้ต้องมีตัวเลขและตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 6 ตัว",

    (v) => v.length >= 6 || "ชื่อผู้ใช้ควรมีความยาวอย่างน้อย 6 ตัว",
    (v) =>
      (!!v || v ? v.length <= 20 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 20 ตัวอักษร",
  ],

  prefix_rules: [(v) => !!v || "กรุณาเลือกข้อมูล"],
  empty_longtext: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => /^[ก-๏A-Za-z0-9-./_ ]+$/.test(v) || "กรุณากรอกข้อมูลให้ถูกต้อง",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 100 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 100 ตัวอักษร",
  ],
  empty_detail_longtext: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    // v => /^[ก-๏A-Za-z0-9-./_ ]+$/.test(v) || "กรุณากรอกข้อมูลให้ถูกต้อง",
    // v => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 500 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 500 ตัวอักษร",
  ],
  empty_other_queries: [(v) => ( v ? v.length <= 255 : false) || " "],

  empty_link: [
    (v) => !!v || "กรุณากรอกข้อมูล หากไม่มีกรุณาขีด '-'",
    // v => /^[ก-๏A-Za-z0-9]+$/.test(v) || "กรุณากรอกข้อมูลให้ถูกต้อง",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 500 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 500 ตัวอักษร",
  ],
  pick_date_rules: [(v) => !!v || "กรุณาเลือกวันที่"],
  price_rulse: [
    (v) => /^[0-9]+$/.test(v) || "กรุณากรอกจำนวนให้ถูกต้อง",
    (v) => v >= 0 || "ราคาต้องมากกว่าหรือเท่ากับ 0 บาท",
    (v) =>
      (v ? (v == 0 ? true : v[0] != "0") : true) || "ราคาไม่ควรเริ่มด้วย'0'",
  ],
  phone_rules: [
    (v) => !!v || "กรุณากรอกหมายเลขโทรศัพท์มือถือ",
    (v) => /^[0-9+-]+$/.test(v) || "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
    (v) => v.length <= 12 || "กรุณากรอกหมายเลขโทรศัพท์มือถือ 10 หลัก",
    (v) => v.length >= 12 || "กรุณากรอกหมายเลขโทรศัพท์มือถือ 10 หลัก",
    (v) => (v ? v[0] == "0" : true) || "เบอร์โทรศัพท์ต้องขึ้นต้นด้วยเลข '0'",
  ],
  staff_name_rules: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) => /^[ก-๏]+$/.test(v) || "ต้องเป็นภาษาไทยเท่านั้น",
    (v) =>
      (v ? v.length <= 50 : false) || "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร",
  ],
  staff_lastname_rules: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) => /^[ก-๏]+$/.test(v) || "ต้องเป็นภาษาไทยเท่านั้น",
    (v) =>
      (v ? v.length <= 50 : false) || "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร",
  ],

  tel_rules: [
    (v) => !!v || "กรุณากรอกเบอร์โทรศัพท์",
    (v) => /^[0-9+-]+$/.test(v) || "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
    (v) =>
      (v ? v.length == 11 || v.length == 12 : false) ||
      "กรุณากรอกเบอร์โทรศัพท์ 9-10 หลัก",
    // v => v.length >= 11 || "กรุณากรอกหมายเลขโทรศัพท์ 9 หลัก",
    (v) => (v ? v[0] == "0" : true) || "เบอร์โทรศัพท์ต้องขึ้นต้นด้วยเลข '0'",
  ],

  email_rules: [
    (v) => !!v || "กรุณากรอกอีเมล",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) => /.+@.+\.+/.test(v) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) => /.+@.+\.+([A-Za-z])+$/.test(v) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) =>
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        v
      ) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) => !/([ก-๏ ])/.test(v) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) =>
      (v ? v.length <= 100 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 100 ตัวอักษร",
  ],
  ps_rules: [
    // v => (v ? v.length <= 50 : false) || "ข้อความมีความยาวมากเกินไป"],
    (v) => (v ? v[0] != " " : true) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (v ? v.length <= 200 : true) ||
      "ข้อความต้องมีความยาวไม่เกิน 200 ตัวอักษร",
  ],
  location_link_rules: [
    (v) => (v ? v[0] != " " : true) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (v ? v.length <= 255 : true) ||
      "ข้อความต้องมีความยาวไม่เกิน 255 ตัวอักษร",
  ],
  no_require_email_rules: [
    (v) => (v ? /.+@.+\.+/.test(v) : true) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) => (v ? v[0] != " " : true) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (v ? /.+@.+\.+([A-Za-z])+$/.test(v) : true) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) => (v ? !/([ก-๏ ])/.test(v) : true) || "กรุณากรอกอีเมลให้ถูกต้อง",
    (v) =>
      v ? v.length <= 100 : true || "ข้อความต้องมีความยาวไม่เกิน 100 ตัวอักษร",
  ],
  address_rules: [
    (v) => !!v || "กรุณากรอกที่อยู่",
    (v) => (v ? v[0] != " " : true) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (v ? v.length <= 255 : true) ||
      "ข้อความต้องมีความยาวไม่เกิน 255 ตัวอักษร",
  ],
  prefixRules: [(v) => !!v || " กรุณาเลือกคำนำหน้าชื่อ"],
  number_rulse: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => /^[0-9]+$/.test(v) || "กรุณากรอกจำนวนให้ถูกต้อง",
    (v) => v > 0 || "จำนวนต้องมากกว่า 0 ",
    (v) => v < 10 || "จำนวนไม่ควรมากกว่า 10 ",
    // (v) => ((!!v) ? v.length < 7 : true) || "ข้อมูลมีความยาวมากเกินไป",
  ],
  time_rulse: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => Number.isInteger(Number(v)) || "จำนวนเต็มเท่านั้น",
    (v) => (!isNaN(parseFloat(v)) && v >= 0) || "ห้ามใส่จำนวนลบ",
  ],
  radioRules: [(v) => !!v || " กรุณาเลือกข้อมูล"],
  km_rulse: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => Number.isInteger(Number(v)) || "จำนวนเต็มเท่านั้น",
    (v) =>
      (!isNaN(parseFloat(v)) && v >= 10 && v <= 100) ||
      "ต้องมากกว่าหรือเท่ากับ10กิโลเมตรแต่ไม่เกิน100กิโลเมตร",
  ],

  // ModuleOrganization
  // add organization non oneID
  rules_name_org: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => /^[ก-๏A-Za-z0-9-./_ ]+$/.test(v) || "กรุณากรอกข้อมูลให้ถูกต้อง",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 50 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร",
  ],
  rules_org_tin: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) =>
      (v ? v.length >= 13 : false) || "* ข้อมูลต้องมีความยาว เท่ากับ 13 ตัว",
  ],

  rule_no_branch: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) => (v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 10 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 10 ตัวอักษร",
  ],
  rulse_radio: [(v) => !!v || "กรุณากรอกข้อมูล"],
  rulse_gp: [(v) => /^\d{1,3}(\.\d{1,2}){0,1}$/.test(v) || "กรุณากรอกข้อมูล"],

  rules_business_id: [
    (v) => !!v || "กรุณากรอกข้อมูล",
    (v) =>
      (v ? v.length >= 16 : false) || "* ข้อมูลต้องมีความยาว อย่างน้อย 16 ตัว",
  ],
  name_package_rules: [
    (v) => !!v || "กรุณากรอกชื่อแพ็คเกจ",
    (v) => (!!v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 50 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร",
  ],
  detail_package_rules: [
    (v) => !!v || "กรุณากรอกรายละเอียดแพ็คเกจ",
    (v) => (!!v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 500 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 500 ตัวอักษร",
  ],
  postal_rulse: [
    (v) => !!v || "กรุณากรอกเป็นตัวเลข.",
    (v) => /^[0-9]{1,}([,.][0-9]{1,})?$/.test(v) || "เฉพาะตัวเลขเท่านั้น.",
    (v) => (v ? v.length <= 6 : false) || "* กรุณากรอกเลขไปรษณีย์",
  ],
  la_long_rulse: [
    (v) => !!v || "กรุณากรอกเป็นตัวเลข.",
    (v) => /^[0-9]{1,}([,.][0-9]{1,})?$/.test(v) || "เฉพาะตัวเลขเท่านั้น.",
    // v => v.length <= 25 || 'กรอกข้อมูลเยอะเกินไป!',
  ],
  name_location_rulse: [
    (v) => /^[A-Za-zก-๏]+$/.test(v) || "กรุณากรอกชื่อสถานที่",
    // v => v.length <= 25 || 'กรอกข้อมูลเยอะเกินไป!',
  ],

  //module Business
  organization_name_rules: [
    (v) => !!v || "กรุณากรอกชื่อองค์กร/ร้านค้า",
    (v) => (!!v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 50 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร",
  ],
  tin_business_rules: [
    (v) => !!v || "กรุณากรอกชื่อเลขประจำตัวผู้เสียภาษี",
    (v) => (!!v ? v[0] != " " : false) || "ห้ามขึ้นต้นด้วยค่าว่าง",
    (v) =>
      (!!v || v ? v.length <= 50 : false) ||
      "ข้อความต้องมีความยาวไม่เกิน 50 ตัวอักษร",
  ],
};
